import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

const Course = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "course-image-level-5.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      ico1: file(relativePath: { eq: "service_ico1.png" }) {
        childImageSharp {
          fixed(width: 74, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Level 5" />
      <BackgroundImage
        Tag="div"
        className="bodhi__section-image-header"
        fluid={data.mainImage.childImageSharp.fluid}
      >
        <h1>
          New Zealand Diploma in Early Childhood Education and Care, Level 5
        </h1>
      </BackgroundImage>
      <div
        id="service_2"
        className="course__services bodhi__bg-pattern margin-header"
      >
        <div className="container">
          <div className="row perk">
            <div className="col-sm-12">
              <p>
                This course is the first course we offer, it is the equivalent
                to the first year of a Bachelor degree. To enrol in the Level 6
                you need to complete the Level 5 first. The course has 4
                modules; Biculturalism, Learning & Development, Inclusive
                Practice and Professional Practice. For each module there are
                written assessments as well as field based key teaching tasks
                that need to be completed, Weekly forums are mandatory to
                promote teina/tuakana learning within the learning cohort.
              </p>
            </div>
            <div className="col-sm-12">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScMXdeEifDxNsjoj94mfQW3kUIZ_hvtHvV0eN24HK2rz4UkEQ/viewform"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-margin-top"
                onClick={() => {
                  window.gtag("event", "apply_online_clicks", {
                    page_name: "level-5",
                    event_type: "apply-online-clicked",
                  })
                }}
              >
                Apply Online
              </a>
            </div>
          </div>
          <div className="row perk">
            <div className="col-sm-2 circle circle1">
              <Img
                fixed={data.ico1.childImageSharp.fixed}
                alt="Icon"
                className="icon-image"
              />
            </div>
            <div className="col-sm-10 description_wrapper">
              <div>
                <h5>
                  New Zealand Diploma in Early Childhood Education and Care
                  (Level 5) 120 Credits.
                </h5>
              </div>
              <div>
                <ul className="bodhi__indented-list">
                  <li>40 week full time course (35 hours per week).</li>
                  <li>2 x 2 week holidays.</li>
                  <li>Online via distance learning.</li>
                  <li>February & July Intake.</li>
                </ul>
                <p>
                  Field Based component
                  <br />
                  14 hours per week must take place in a licensed early
                  childhood service.
                  <br />1 x 4 week in-home field based block (35 hours per
                  week).
                </p>
              </div>
              <div>
                <br />
                <h5>Entry Criteria</h5>
                <ul className="bodhi__indented-list">
                  <li>NZ Citizens / Permanent Resident.</li>
                  <li>Met ‘fit to be a practitioner’ criteria.</li>
                  <li>Competent computer skills.</li>
                  <li>Satisfactory Police Vetting Check.</li>
                  <li>Entry Test.</li>
                  <li>
                    Achieved a minimum of NCEA level 2, or demonstrate knowledge
                    and experience at this level.
                  </li>
                  <li>Succeed in an interview.</li>
                </ul>
              </div>
              <div>
                <br />
                <h5>Fees (2023): $5480.00</h5>
              </div>
            </div>
          </div>
          <div className="row perk">
            <div className="col-sm-2"></div>
            <div className="col-sm-10 description_wrapper">
              <div>
                <div>
                  <h5>Field Based Definitions.</h5>
                  <p className="small">
                    Supervised: - the learner will have a suitable
                    qualified/experienced supervising/supporting mentor who will
                    assess the student’s practice against requirements. For
                    students in a licensed based service, supervision will be an
                    Associate Tutor belonging to the service, and a visiting
                    tutor from Bodhi. Those students in a home-based situation
                    will be mentored by an Associate Tutor from Bodhi Ltd, who
                    will also act as their visiting tutor.
                  </p>
                  <p className="small">
                    ECE setting – this must be a recognised early childhood
                    education (ECE) service, which may include a certificated
                    playgroup or a licensed centre-based service, parent-led
                    service, hospital-based service, home-based service; or
                    culturally-based environment. The home-based service may be
                    the child’s own home, or the home of the educator.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Course
